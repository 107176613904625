import React, { useEffect, useRef } from 'react';
import uuid from 'react-uuid';
import t from 'react-translate';
import { css } from '@emotion/core';
import { Button } from 'react-bootstrap';
import { DndContext, DragOverlay, KeyboardSensor, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, horizontalListSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { restrictToWindowEdges } from '@dnd-kit/modifiers';

// redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { wrapThunkAction } from 'redux/utils';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import { getPointsConfiguration } from 'redux/selectors/points-configurations';
import {
  addQuizQuestion,
  deleteQuizQuestion,
  duplicateQuizQuestion,
  updateQuestionsOrder,
  reorderQuestions,
} from 'redux/actions/quizzes';
import { getFlatCourseAliases } from 'redux/selectors/course';

import { DeepPartial } from 'utility-types';
import NvIcon from 'shared/components/nv-icon';
import NvTooltip from 'shared/components/nv-tooltip';
import AttemptHeart from 'quizzes/components/attempt-heart';
import ProgressiveQuizContext, { SavingIndicator } from 'quizzes/components/context';
import { NQuizQuestion, QuizQuestionType } from 'redux/schemas/models/progressive-quiz';
import { SavingRegistryContext } from 'shared/hooks/use-saving-registry';
import { halfSpacing, largeSpacing, quarterSpacing, threeQuartersSpacing, tripleSpacing } from 'styles/global_defaults/scaffolding';
import QuestionPopover, { PopoverVariant } from 'quizzes/components/question-popover';
import useProgressiveQuizNavigation from 'quizzes/hooks/use-progressive-quiz-navigation';
import ClickableContainer, { ClickableContainerProps } from 'components/clickable-container';
import {
  gray1,
  gray3,
  gray4,
  gray6,
  white,
  black,
  lowTide,
  primary,
  warning,
  success,
  highTide,
  gray5,
  danger,
} from 'styles/global_defaults/colors';
import NvDropdown, { NvDropdownButtonStyle, NvDropdownCustomItem } from 'shared/components/inputs/nv-dropdown';
import useQuizModeAndQuestionType from 'quizzes/hooks/use-quiz-mode-and-question-type';
import { isEmpty, last } from 'underscore';
import { getQuizQuestionResponse } from 'redux/selectors/quizzes';
import { config } from '../../../config/config.json';

type Props = {
  className?: string;
};

const QuizHeader = (props: Props) => {
  const { className } = props;

  const dispatch = useAppDispatch();
  const courseAliases = useSelector(getFlatCourseAliases);
  const { registerSaving } = React.useContext(SavingRegistryContext);
  const [isAddingQuestion, setIsAddingQuestion] = React.useState(false);
  const [isDragging, setIsDragging] = React.useState(false);
  const attemptsContainerRef = React.useRef(null);
  const [showDropdown, setShowDropdown] = React.useState(false);

  const {
    questions,
    progressiveQuiz,
    currentQuestion,
    currentQuestionIndex,
    setCurrentQuestionIndex,
    currentQuestionResponse,
    canMakeStructuralChanges,
    requiredCorrectQuestionsCount,
    savingIndicatorTimeoutRef,
    setSavingStatus,
    isAllQuestionViewQuiz,
  } = React.useContext(ProgressiveQuizContext);

  const {
    isAnswerMode,
    isEditMode,
    isReviewMode,
    isLongAnswerQuestion,
    isStatement,
  } = useQuizModeAndQuestionType();

  const failedQuestionAttempts = (currentQuestion?.completedQuestionAttempts ?? 0) - (currentQuestionResponse?.isCorrect ? 1 : 0);

  React.useEffect(() => {
    if (attemptsContainerRef.current && !isStatement) {
      attemptsContainerRef.current.blur();
    }
  }, [isStatement]);

  const notEditModeQuestions = React.useMemo<DeepPartial<NQuizQuestion>[]>(() => Array(progressiveQuiz?.totalQuestions).fill(null).map((_, index) => ({
    index,
    id: uuid(),
  })), [progressiveQuiz?.totalQuestions]);

  const questionsToRender = isEditMode ? questions : notEditModeQuestions;

  const pointsConfiguration = useSelector((state) => (
    getPointsConfiguration(state, progressiveQuiz?.pointsConfiguration || 0)
  ));

  const styles = css`
    background-color: ${white};

    .questions-container {
      ${isDragging && css`
        max-width: 680px;
        overflow-x: hidden;
        border: 1px dotted ${primary};
        padding-left: ${halfSpacing}px;
        padding-right: ${halfSpacing}px;
        border-radius: ${quarterSpacing}px;
      `}
    }

    .header-container {
      width: 100%;

      .quiz-progress {
        margin-bottom: 8px;
      }

      .correct-indicator {
        display: ${isStatement ? 'none' : 'flex'};
        .icon {
          color: ${success};
        }
      }

      .dropdown-container {
        width: 176px;
        top: ${halfSpacing}px !important;
        left: -${tripleSpacing}px !important;
        border-color: ${gray5} !important;
        .bs4-dropdown-item {
          padding: ${halfSpacing}px 0px ${halfSpacing}px ${threeQuartersSpacing}px !important;
        }
      }
    }
  `;

  const handleAddQuestion = (questionType: string) => {
    const unregister = registerSaving();

    setShowDropdown(false);
    setIsAddingQuestion(true);
    setSavingStatus(SavingIndicator.SAVING_STATUS);
    clearTimeout(savingIndicatorTimeoutRef.current);

    wrapThunkAction(dispatch(addQuizQuestion({
      questionSetId: progressiveQuiz?.id,
      currentQuestionId: currentQuestion.id,
      questionType,
      isAllQuestionViewQuiz,
    }))).then(() => {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setSavingStatus(SavingIndicator.SUCCESS_STATUS);
    }).catch((e) => {
      setSavingStatus(SavingIndicator.ERROR_STATUS);
    }).finally(() => {
      setIsAddingQuestion(false);
      unregister();

      savingIndicatorTimeoutRef.current = setTimeout(() => {
        setSavingStatus(SavingIndicator.HIDDEN_STATUS);
      }, 2000);
    });
  };

  const isAddQuestionDisabled = !canMakeStructuralChanges || isAddingQuestion;

  const correctQuestionsCount = progressiveQuiz?.correctAnswersCount;

  const hasMetRequirement = correctQuestionsCount >= requiredCorrectQuestionsCount;

  const questionTypes: NvDropdownCustomItem[] = [
    {
      type: 'custom',
      customItem: (
        <div className='d-flex mt-2 pl-3'>
          <p className='mb-0 text-small semi-bold text-gray-2'>{t.QUIZZES.PROGRESSIVE_QUIZ_BASICS.PICK_A_QUESTION_TYPE()}</p>
        </div>
      ),
    },
    {
      type: 'custom',
      customItem: (
        <ClickableContainer
          onClick={() => handleAddQuestion(QuizQuestionType.MULTIPLE_CHOICE)}
          className='d-flex mt-2 bs4-dropdown-item'
          data-qa={config.pendo.activities.progressiveQuiz?.questionModal.multipleChoiceQuestion.addMultipleChoiceQuestion}
        >
          <NvIcon className='d-flex align-items-center' icon='multiple-choice' size='xs-smallest' />
          <p className='mb-0 ml-3'>{t.QUIZZES.PROGRESSIVE_QUIZ_BASICS.MULTIPLE_CHOICE()}</p>
        </ClickableContainer>
      ),
    },
    {
      type: 'custom',
      customItem: (
        <ClickableContainer
          onClick={() => handleAddQuestion(isAllQuestionViewQuiz ? QuizQuestionType.ALL_QUESTION_VIEW_SHORT_TEXT_QUESTION : QuizQuestionType.SHORT_TEXT_QUESTION)}
          className='d-flex bs4-dropdown-item'
          data-qa={config.pendo.activities.progressiveQuiz?.questionModal.shortAnswerQuestion.addShortAnswerQuestion}
        >
          <NvIcon className='d-flex align-items-center' icon='length' size='xs-smallest' />
          <p className='mb-0 ml-3'>{t.QUIZZES.PROGRESSIVE_QUIZ_BASICS.SHORT_ANSWER()}</p>
        </ClickableContainer>
      ),
    },
    {
      type: 'custom',
      customItem: (
        <ClickableContainer
          onClick={() => handleAddQuestion(QuizQuestionType.NUMBER_QUESTION)}
          className='d-flex bs4-dropdown-item'
          data-qa={config.pendo.activities.progressiveQuiz?.questionModal.numberAnswerQuestion.addNumberAnswerQuestion}
        >
          <NvIcon className='d-flex align-items-center semi-bold' icon='number' size='xs-smallest' />
          <p className='mb-0 ml-3'>{t.QUIZZES.PROGRESSIVE_QUIZ_BASICS.NUMBER()}</p>
        </ClickableContainer>
      ),
    },
    {
      type: 'custom',
      customItem: (
        <ClickableContainer
          onClick={() => handleAddQuestion(QuizQuestionType.STATEMENT)}
          className='d-flex bs4-dropdown-item'
          data-qa={config.pendo.activities.progressiveQuiz?.questionModal.statement.addStatement}
        >
          <NvIcon className='d-flex align-items-center semi-bold' icon='read' size='xs-smallest' />
          <p className='mb-0 ml-3'>{t.QUIZZES.PROGRESSIVE_QUIZ_BASICS.STATEMENT()}</p>
        </ClickableContainer>
      ),
    },
  ];

  if (!isAllQuestionViewQuiz) {
    questionTypes.splice(3, 0, {
      type: 'custom',
      customItem: (
        <ClickableContainer
          onClick={() => handleAddQuestion(QuizQuestionType.LONG_ANSWER_QUESTION)}
          className='d-flex bs4-dropdown-item'
          data-qa={config.pendo.activities.progressiveQuiz?.questionModal.longAnswerQuestion.addLongAnswerQuestion}
        >
          <NvIcon className='d-flex align-items-center' icon='length-long' size='xs-smallest' />
          <p className='mb-0 ml-3'>{t.QUIZZES.PROGRESSIVE_QUIZ_BASICS.LONG_ANSWER()}</p>
        </ClickableContainer>
      ),
    });
  }

  return (
    <div className={`pt-4 pb-4 d-flex justify-content-center ${className}`} css={styles}>
      <div className='header-container'>
        <div className={`d-flex align-items-center mb-2 ${isReviewMode ? 'justify-content-center' : 'justify-content-between'}`}>
          <div className='text-large-body bold'>
            {isStatement ? t.QUIZZES.PROGRESSIVE_QUIZ_BASICS.STATEMENT() : t.QUIZZES.CURRENT_QUESTION_INDICATOR(currentQuestion.answerableQuestionPosition, progressiveQuiz?.answerableQuestionsCount)}
          </div>
          {isEditMode && (
            <NvDropdown
              show={showDropdown && canMakeStructuralChanges}
              menuClassName='dropdown-container'
              title={t.INSTITUTIONS.CONTENT_LIBRARY.USER_MANAGEMENT.MANAGE_USERS.BUTTON_TEXT()}
              buttonStyle={NvDropdownButtonStyle.CUSTOM}
              onToggle={() => setShowDropdown(!showDropdown)}
              customTarget={() => (
                <NvTooltip enabled={!canMakeStructuralChanges} text={t.QUIZZES.ALREADY_RELEASED.ADD_QUESTION(courseAliases)}>
                  <Button
                    size='sm'
                    variant='secondary'
                    disabled={isAddQuestionDisabled}
                    className='d-flex flex-row align-items-center'
                    data-qa={config.pendo.activities.progressiveQuiz?.questionModal.toggleAddQuestionDropdown}
                  >
                    <NvIcon icon={isAddingQuestion ? 'refresh' : 'create-new-post'} size='smallest' className='mr-2 d-flex' />
                    {t.QUIZZES.ADD_QUESTION()}
                  </Button>
                </NvTooltip>
              )}
              items={questionTypes}
              allowOverflow
            />

          )}
          {isAnswerMode && (
            <>
              {!!correctQuestionsCount && (() => {
                const requirementText = hasMetRequirement ? t.QUIZZES.REQUIREMENT_MET(correctQuestionsCount) : t.QUIZZES.KEEP_GOING(correctQuestionsCount, requiredCorrectQuestionsCount);

                const text = pointsConfiguration?.passingScoreRequired ? requirementText : t.QUIZZES.CORRECT_SO_FAR(correctQuestionsCount);

                return (
                // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                  <div className='correct-indicator align-items-center' tabIndex={0} aria-label={text}>
                    <div className='text-body-large bold mr-2'>{correctQuestionsCount}</div>
                    <NvTooltip text={text}>
                      <QuestionPopover variants={[
                        PopoverVariant.CORRECT_ANSWER,
                        PopoverVariant.REQUIREMENT_MET]}
                      >
                        <NvIcon icon='check' size='small' />
                      </QuestionPopover>
                    </NvTooltip>
                  </div>
                );
              })()}
            </>
          )}
        </div>
        <QuestionNavigator
          className='mb-2'
          questions={questionsToRender}
          isDragging={isDragging}
          setIsDragging={setIsDragging}
        />
        {!isReviewMode && (progressiveQuiz?.questionMaximumAttempts > 1)
          && !(isStatement || isLongAnswerQuestion)
          && (
          <div className='d-flex justify-content-center'>
            <NvTooltip text={t.QUIZZES.SET_QUESTION_ATTEMPT_FROM_BASICS()} placement='bottom' enabled={isEditMode}>
              {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
              <div className='d-flex' tabIndex={0} ref={attemptsContainerRef}>
                <div className='label gray-3 mr-2'>{t.QUIZZES.REMAINING_QUESTION_ATTEMPTS()}</div>
                <div className='sr-only'>{progressiveQuiz?.questionMaximumAttempts - failedQuestionAttempts}</div>
                <div className='d-flex flex-row align-items-center'>
                  {Array.from(Array(progressiveQuiz?.questionMaximumAttempts).keys()).map((_, index) => {
                    const isAttemptUsed = isEditMode ? false : (index + 1) > (progressiveQuiz?.questionMaximumAttempts - failedQuestionAttempts);

                    const isFirst = index === 0;
                    const isLast = index === (progressiveQuiz?.questionMaximumAttempts - 1);

                    return (
                      <div
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        className='mr-1'
                        data-qa={config.pendo.activities.progressiveQuiz?.questionModal.remainingQuestionAttemptHeart}
                        data-qa-id={`${config.pendo.activities.progressiveQuiz?.questionModal.remainingQuestionAttemptHeart}_${index + 1}`}
                      >
                        <QuestionPopover
                          variants={[
                            isFirst ? PopoverVariant.FAILED_QUESTTION : undefined,
                            isLast ? PopoverVariant.FIRST_MISTAKE : undefined,
                          ].filter(Boolean)}
                        >
                          <AttemptHeart used={isAttemptUsed} />
                        </QuestionPopover>
                      </div>
                    );
                  })}
                </div>
              </div>
            </NvTooltip>
          </div>
          )}
      </div>
    </div>
  );
};

const ITEM_SIZE = largeSpacing;
const ITEM_SPACING = halfSpacing;

const MINIMUM_VISIBLE_ELEMENTS = 3;

type QuestionNavigatorProps = {
  questions: DeepPartial<NQuizQuestion>[];
  className?: string;
  isDragging: boolean;
  setIsDragging: Function;
};

const QuestionNavigator = (props: QuestionNavigatorProps) => {
  const { className, questions, isDragging, setIsDragging } = props;

  const { maxIndexAllowed } = useProgressiveQuizNavigation();
  const { registerSaving } = React.useContext(SavingRegistryContext);

  const containerRef = React.useRef<HTMLDivElement>();
  const [maxVisibleElements, setMaxVisibleElements] = React.useState(MINIMUM_VISIBLE_ELEMENTS);
  const [prevQuestions, setPrevQuestions] = React.useState([]);

  const { isPreviousDisabled, isNextDisabled, previous, next } = useProgressiveQuizNavigation();

  const {
    currentQuestionIndex,
    setCurrentQuestionIndex,
    canMakeStructuralChanges,
    setSavingStatus,
    savingIndicatorTimeoutRef,
    progressiveQuiz,
    fetchQuestion,
    isAllQuestionViewQuiz,
  } = React.useContext(ProgressiveQuizContext);

  const {
    isEditMode,
    isAnswerMode,
  } = useQuizModeAndQuestionType();

  const dispatch = useAppDispatch();

  const calculateMaxVisibleElements = React.useCallback(() => {
    const containerWidth = containerRef.current.getBoundingClientRect().width;

    let amount = MINIMUM_VISIBLE_ELEMENTS;
    let spaceRequiredForAmount = (ITEM_SIZE * amount) + ((amount - 1) * ITEM_SPACING);

    while (spaceRequiredForAmount < containerWidth) {
      amount += 1;
      spaceRequiredForAmount = (ITEM_SIZE * amount) + ((amount - 1) * ITEM_SPACING);
    }

    setMaxVisibleElements(amount);
  }, []);

  React.useEffect(() => {
    calculateMaxVisibleElements();

    window.addEventListener('resize', calculateMaxVisibleElements);

    return () => window.removeEventListener('resize', calculateMaxVisibleElements);
  }, [calculateMaxVisibleElements]);

  const styles = css`
    .item {
      margin-right: ${ITEM_SPACING}px;
    }
  `;

  const elementsToRender: (NQuizQuestion & { index: number })[] = React.useMemo(() => {
    // Decreasing "previous" and "next" items
    const maxQuestionsElements = maxVisibleElements - 2;

    const addInitialIndex = (question, index) => ({
      ...question,
      index,
    });

    if (questions.length <= maxQuestionsElements || isDragging) {
      return questions.map(addInitialIndex);
    }

    const isQuestionElementsPair = !(maxQuestionsElements % 2);

    const leftElementsCount = Math.trunc(maxQuestionsElements / 2);

    let right = currentQuestionIndex + (isQuestionElementsPair ? leftElementsCount : (leftElementsCount + 1));

    let left = currentQuestionIndex - leftElementsCount;

    const leftOverflows = left < 0;
    const rightOverflows = right > (questions.length - 1);

    if (leftOverflows && rightOverflows) {
      return questions;
    }

    if (leftOverflows) {
      right += -left;
      left = 0;
    } else if (rightOverflows) {
      left -= right - questions.length;
      right = questions.length;
    }

    return questions.map(addInitialIndex).slice(left, right);
  }, [maxVisibleElements, isDragging, questions, currentQuestionIndex]);

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 5, // Enable sort function when dragging 5px
    },
  });

  const keyboardSensor = useSensor(KeyboardSensor);
  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      distance: 5, // Enable sort function when dragging 5px
    },
  });
  const sensors = useSensors(mouseSensor, keyboardSensor, touchSensor);
  const [activeData, setActiveData] = React.useState(null);

  const handleDragStart = (e) => {
    setPrevQuestions([...progressiveQuiz?.questions]);
    setActiveData({ id: e.active.data.id, index: e.active.data.current.index });
    setCurrentQuestionIndex(e.active.data.current.index);
    setIsDragging(true);
  };

  const handleDragEnd = (e) => {
    const { active, over } = e;
    setActiveData(null);

    if (over?.data) {
      setDisableDragging(true);
      setSavingStatus(SavingIndicator.SAVING_STATUS);
      clearTimeout(savingIndicatorTimeoutRef.current);
      // Dispatching this action to reorder questions locally
      dispatch(updateQuestionsOrder(
        { questionSetId: progressiveQuiz?.id,
          addedIndex: over.data.current.sortable.index,
          removedIndex: active.data.current.sortable.index,
          isAllQuestionViewQuiz,
        },
      ));
      setCurrentQuestionIndex(over.data.current.sortable.index);
    }
    // given setTimeout for smooth transition
    setTimeout(() => {
      setIsDragging(false);
    }, 500);

    if (over?.data) {
      // Dispatching this action to reorder questions on server
      dispatch(reorderQuestions({
        questionSetId: progressiveQuiz?.id,
        questionId: active.id,
        addedIndex: over.data.current.sortable.index,
        prevQuestions,
        isAllQuestionViewQuiz,
      })).then((res) => {
        if (res.error) {
          setSavingStatus(SavingIndicator.ERROR_STATUS);
          setCurrentQuestionIndex(prevQuestions.findIndex((question) => question === active.id));
        } else {
          setSavingStatus(SavingIndicator.SUCCESS_STATUS);
        }
        savingIndicatorTimeoutRef.current = setTimeout(() => {
          setSavingStatus(SavingIndicator.HIDDEN_STATUS);
        }, 2000);
        setDisableDragging(false);
        setPrevQuestions([]);
      });
    }
  };
  const questionContainerRef = useRef(null);
  const [disableDragging, setDisableDragging] = React.useState(false);

  return (
    <div
      css={styles}
      ref={containerRef}
      className={`d-flex justify-content-center ${className}`}
    >
      {isEditMode ? (
        <QuestionNavigatorNavigationalItem
          onClick={previous}
          className='item'
          disabled={isPreviousDisabled}
        />
      ) : (
        <React.Fragment>
          {elementsToRender[0].index !== 0 && (
            <div className='p-2 text-gray-1'>
              <NvIcon icon='more' size='xss-smallest' />
            </div>
          )}
        </React.Fragment>
      )}
      <DndContext
        modifiers={[restrictToWindowEdges]}
        sensors={sensors}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          disabled={disableDragging || !isEditMode || !canMakeStructuralChanges}
          id='draggable'
          items={elementsToRender}
          strategy={horizontalListSortingStrategy}
        >
          <div ref={questionContainerRef} className='d-flex questions-container'>
            {elementsToRender.map((question) => {
              const handleItemClick = () => {
                if (isAnswerMode) {
                  /**
                   * We only have basic question details on the first load in non-edit
                   * mode, so we are requesting the full details when switching.
                   */
                  fetchQuestion({
                    page: question.index + 1,
                  });
                } else {
                  setCurrentQuestionIndex(question.index);
                }
              };

              const handleItemDuplicate = () => {
                const unregister = registerSaving();
                setSavingStatus(SavingIndicator.SAVING_STATUS);
                clearTimeout(savingIndicatorTimeoutRef.current);

                wrapThunkAction(dispatch(duplicateQuizQuestion(
                  {
                    questionId: question.id,
                    isAllQuestionViewQuiz,
                  },
                ))).then(() => {
                  setCurrentQuestionIndex(question.index + 1);
                  setSavingStatus(SavingIndicator.SUCCESS_STATUS);
                }).catch(() => {
                  setSavingStatus(SavingIndicator.ERROR_STATUS);
                }).finally(() => {
                  unregister();

                  savingIndicatorTimeoutRef.current = setTimeout(() => {
                    setSavingStatus(SavingIndicator.HIDDEN_STATUS);
                  }, 2000);
                });
              };

              const handleItemDelete = () => {
                dispatch(openConfirmationDialog({
                  confirmText: t.FORM.YES_SURE(),
                  bodyText: t.SHARED.IRRECOVERABLE(),
                  title: t.QUIZZES.DELETE_QUESTION_CONFIRMATION(),
                  onConfirm: () => {
                    const unregister = registerSaving();
                    setSavingStatus(SavingIndicator.SAVING_STATUS);
                    clearTimeout(savingIndicatorTimeoutRef.current);

                    wrapThunkAction(dispatch(deleteQuizQuestion(
                      {
                        questionToDeleteId: question.id,
                        isAllQuestionViewQuiz,
                      },
                    ))).then(() => {
                      if (question.index <= currentQuestionIndex) {
                        setCurrentQuestionIndex(currentQuestionIndex ? (currentQuestionIndex - 1) : 0);
                      }
                      setSavingStatus(SavingIndicator.SUCCESS_STATUS);
                    }).catch(() => {
                      setSavingStatus(SavingIndicator.ERROR_STATUS);
                    }).finally(() => {
                      unregister();

                      savingIndicatorTimeoutRef.current = setTimeout(() => {
                        setSavingStatus(SavingIndicator.HIDDEN_STATUS);
                      }, 2000);
                    });
                  },
                }));
              };

              return (
                <QuestionNavigatorItem
                  className='item'
                  key={question.id}
                  questionId={question.id}
                  index={question.index}
                  onClick={handleItemClick}
                  onDelete={handleItemDelete}
                  onDuplicate={handleItemDuplicate}
                  disabled={maxIndexAllowed < question.index}
                  active={question.index === currentQuestionIndex}
                  disableDragging={disableDragging}
                />
              );
            })}
          </div>
        </SortableContext>
        <DragOverlay>
          {activeData && !disableDragging && (
            <QuestionNavigatorItem
              className='item'
              isDragOverlay
              key={activeData.id}
              questionId={activeData.id}
              index={activeData.index}
              disabled={maxIndexAllowed < activeData.index}
              active={activeData.index === currentQuestionIndex}
            />
          )}
        </DragOverlay>
      </DndContext>
      {isEditMode ? (
        <QuestionNavigatorNavigationalItem
          next
          onClick={next}
          className='item mr-0'
          disabled={isNextDisabled}
        />
      ) : (
        <React.Fragment>
          {(last(elementsToRender).index < (questions.length - 1)) && (
            <div className='p-2 text-gray-1'>
              <NvIcon icon='more' size='xss-smallest' />
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

const getItemBaseStyles = (disabled: boolean, active: boolean, borderColor: string = gray6) => css`
  border-radius: 6px;
  width: ${ITEM_SIZE}px;
  height: ${ITEM_SIZE}px;
  ${!active && !disabled && css`
    &:hover {
      border: 1px solid ${borderColor};
    }
  `};
`;

type QuestionNavigatorItemProps = {
  index: number;
  active: boolean;
  className?: string;
  onClick?: ClickableContainerProps['onClick'];
  onDuplicate?: () => void;
  onDelete?: () => void;
  disabled?: boolean;
  questionId?: number;
  isDragOverlay?: boolean;
  disableDragging?: boolean;
};

type LearnerNavigationContentProps = Pick<QuestionNavigatorItemProps, 'index' | 'active'> & {
  isCorrect: boolean,
  isWrong: boolean,
};

const LearnerNavigationContent = (props: LearnerNavigationContentProps) => {
  const { index, active, isCorrect, isWrong } = props;
  const { questions } = React.useContext(ProgressiveQuizContext);
  const currentQuestion: NQuizQuestion = questions[index];

  const styles = css`
    // Overriding icon font size
    .extra-small-icon {
      font-size: ${halfSpacing}px;
    }
  `;

  const getContent = () => {
    if (isCorrect) {
      return <NvIcon icon='check' size='smallest' className='text-success' />;
    }
    if (isWrong) {
      return (
        <NvIcon
          icon='close'
          size='smallest'
          className='extra-small-icon text-danger'
        />
      );
    }

    return index + 1;
  };

  return (
    <span css={styles}>
      {(currentQuestion?.type === QuizQuestionType.STATEMENT) ? (
        <NvTooltip
          text={t.QUIZZES.PROGRESSIVE_QUIZ_BASICS.STATEMENT()}
          offset={active ? 5 : 0}
        >
          <NvIcon
            icon='read'
            size='smallest'
            className={`text-${active ? 'white' : 'gray-2'}`}
          />
        </NvTooltip>
      ) : getContent()}
    </span>
  );
};

const TOOLTIP_ITEM_SIZE = 36;
const TOOLTIP_ARROW_SIZE = 4;

const QuestionNavigatorItem = (props: QuestionNavigatorItemProps) => {
  const {
    index,
    active,
    onClick,
    onDelete,
    className,
    onDuplicate,
    disabled = false,
    questionId,
    isDragOverlay,
    disableDragging,
  } = props;

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging } = useSortable({ id: questionId, data: { index } });

  const {
    isReviewMode,
  } = useQuizModeAndQuestionType();

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const [show, setShow] = React.useState(false);
  const courseAliases = useSelector(getFlatCourseAliases);
  const { progressiveQuiz, questions } = React.useContext(ProgressiveQuizContext);
  const { canMakeStructuralChanges } = React.useContext(ProgressiveQuizContext);
  const questionResponse = useSelector((state) => getQuizQuestionResponse(state, (isReviewMode ? questions[index]?.previousAttemptResponses : questions[index]?.responses)));

  const {
    isEditMode,
    isAnswerMode,
  } = useQuizModeAndQuestionType();

  useEffect(() => {
    if (isDragging) {
      setShow(false);
    }
  }, [isDragging]);

  const questionIsStatement = questions[index]?.type === QuizQuestionType.STATEMENT;
  const showLearnerNavigation = !isEditMode && (!show || questionIsStatement);

  const isCorrect = !isEmpty(questionResponse) && questionResponse?.isCorrect;
  const isWrong = (() => {
    const isIncorrect = (!isEmpty(questionResponse) && !questionResponse?.isCorrect);
    const exhaustAttempts = questionResponse?.completedQuestionAttempts >= progressiveQuiz?.questionMaximumAttempts;

    // Active in answer mode, considering wrong when user exhaust all the attempts with incorrect reponse
    return (isAnswerMode && active) ? (isIncorrect && exhaustAttempts) : isIncorrect;
  })();

  const hasValidResponse = isCorrect || isWrong;
  const { fontColor, backgroundColor } = (() => {
    if (!questionIsStatement
      && (
        // If showing the learner navigation and has a considered user response.
        (showLearnerNavigation && hasValidResponse)
        // For handling the hover state color.
        || (!isEditMode && hasValidResponse && show)
      )
    ) {
      return {
        fontColor: questionResponse?.isCorrect ? success : danger,
        backgroundColor: 'transparent',
      };
    }

    return {
      fontColor: active ? white : gray1,
      backgroundColor: active ? primary : 'transparent',
    };
  })();

  const styles = css`
    color: ${fontColor};
    // Overriding the border color for the statement.
    ${getItemBaseStyles(active, disabled, (questionIsStatement && !isEditMode) ? white : fontColor)};
    background-color: ${backgroundColor};
    ${active && css`
      outline-color: ${warning}!important;
    `};

    ${active && !isEditMode && !isEmpty(questionResponse) && css`
      border: 1px solid ${fontColor};
    `};

    ${!isAnswerMode && css`
      &[aria-disabled='true'] {
        color: ${gray4};
      }
    `};

    height: ${largeSpacing}px;
    min-width: ${largeSpacing}px;
  `;

  const tooltipStyles = css`
    display: ${(show && !isDragging) ? 'block' : 'none'};
    padding-bottom: ${TOOLTIP_ARROW_SIZE}px;
    top: -${TOOLTIP_ITEM_SIZE + TOOLTIP_ARROW_SIZE}px;

    &:before {
      left: 50%;
      bottom: 0;
      content: "";
      position: absolute;
      border-style: solid;
      border-color: transparent;
      border-top-color: ${black};
      transform: translateX(-50%);
      border-width: ${TOOLTIP_ARROW_SIZE}px ${TOOLTIP_ARROW_SIZE}px 0;
    }

    .tooltip-body {
      color: ${white};
      background-color: ${black};
      border-radius: ${quarterSpacing}px;

      .tooltip-option {
        width: ${TOOLTIP_ITEM_SIZE}px;
        height: ${TOOLTIP_ITEM_SIZE}px;

        &:not([aria-disabled=true]):hover {
          background-color: ${gray3};
        }
      }
    }
  `;

  const isOneQuestionLeft = questions.length === 1;

  const isDeleteQuestionDisabled = isOneQuestionLeft;

  const dropPlaceHolderStyle = css`
    min-height: ${largeSpacing}px;
    min-width: ${largeSpacing}px;
    background-color: ${gray6};
    border-radius: 6px;
  `;

  if (isDragging && !isDragOverlay && !disableDragging) {
    return (
      <div ref={setNodeRef} css={dropPlaceHolderStyle} style={style} />
    );
  }

  return (
    <ClickableContainer
      css={styles}
      onMouseOver={() => setShow(true)}
      onMouseOut={() => setShow(false)}
      onClick={onClick}
      key={questionId}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={style}
      disabled={disabled}
      className={`d-flex align-items-center justify-content-center ${className} label position-relative`}
      data-qa={config.pendo.activities.progressiveQuiz.questionModal.questionNavigatorButton}
      data-qa-id={`${config.pendo.activities.progressiveQuiz.questionModal.questionNavigatorButton}_${index + 1}`}
    >
      {showLearnerNavigation
        ? (
          <LearnerNavigationContent
            index={index}
            active={active}
            isCorrect={isCorrect}
            isWrong={isWrong}
          />
        ) : (index + 1)}
      {isEditMode && (
      <div css={tooltipStyles} className='position-absolute' onClick={(e) => e.stopPropagation()}>
        <div className='d-flex tooltip-body flex-row pl-1 pr-1'>
          <NvTooltip text={!canMakeStructuralChanges ? t.QUIZZES.ALREADY_RELEASED.DUPLICATE(courseAliases) : t.QUIZZES.DUPLICATE_QUESTION()} preventOverflow={false}>
            <ClickableContainer
              onClick={onDuplicate}
              disabled={!canMakeStructuralChanges}
              className='tooltip-option p-2'
              data-qa={config.pendo.activities.progressiveQuiz.questionModal.questionNavigatorButtonDuplicate}
              data-qa-id={`${config.pendo.activities.progressiveQuiz.questionModal.questionNavigatorButtonDuplicate}_${index + 1}`}
            >
              <NvIcon icon='duplicate' size='smallest' className={!canMakeStructuralChanges ? 'gray-3' : undefined} />
            </ClickableContainer>
          </NvTooltip>
          {!isDeleteQuestionDisabled && (
          <NvTooltip text={!canMakeStructuralChanges ? t.QUIZZES.ALREADY_RELEASED.QUESTION_DELETE(courseAliases) : t.QUIZZES.DELETE_QUESTION()} preventOverflow={false}>
            <ClickableContainer
              onClick={onDelete}
              disabled={!canMakeStructuralChanges}
              className='tooltip-option p-2'
              data-qa={config.pendo.activities.progressiveQuiz.questionModal.questionNavigatorButtonDelete}
              data-qa-id={`${config.pendo.activities.progressiveQuiz.questionModal.questionNavigatorButtonDelete}_${index + 1}`}
            >
              <NvIcon icon='trash' size='smallest' className={!canMakeStructuralChanges ? 'gray-3' : undefined} />
            </ClickableContainer>
          </NvTooltip>
          )}
        </div>
      </div>
      )}
    </ClickableContainer>
  );
};

type QuestionNavigatorNavigationalItemProps = {
  onClick: ClickableContainerProps['onClick'];
  next?: boolean;
  className?: string;
  disabled?: boolean;
} ;

const QuestionNavigatorNavigationalItem = (props: QuestionNavigatorNavigationalItemProps) => {
  const { className, next = false, onClick, disabled } = props;

  return (
    <ClickableContainer
      onClick={onClick}
      disabled={disabled}
      css={getItemBaseStyles(disabled, false)}
      aria-label={next ? t.SHARED.NEXT() : t.SHARED.PREVIOUS()}
      className={`d-flex align-items-center justify-content-center ${className}`}
      data-qa={config.pendo.activities.progressiveQuiz.questionModal[next ? 'questionNavigatorNext' : 'questionNavigatorPrevious']}
    >
      <NvIcon size='xss-smallest' icon={`arrow-${next ? 'right' : 'left'} ${disabled ? 'gray-4' : 'black'}`} />
    </ClickableContainer>
  );
};

export default QuizHeader;
