import { PointsConfiguration } from './points-configuration';
import { MinimalActivity, ActivityType } from './activity';
import { Communication } from './course-communication';
import { Normalized } from '../normalized';
import { FileUpload } from './file-uploads';
import {
  QuestionFileUpload,
  QuizQuestion,
  QuizQuestionFeedback,
  QuizQuestionResponse,
  QuizQuestionType,
  ResponseOption,
} from './progressive-quiz';

export interface MinimalQuiz extends MinimalActivity {
  type: ActivityType.QUIZ
}

export type TimelineQuiz = {
  id: number;
  title: string;
  ownerTitle?: string;
  titleIndex?: number;
  isRequiredForCompletion: boolean;
  expirationDate: string | null;
  releaseDate: string;
  answerableQuestionsCount: number;
  pointsConfiguration: PointsConfiguration;
  activityType: string;
  totalPoints: number[];
  pointsReceived: number;
  releaseResultsAt?: string;
  timeLimit?: number;
  timeInterval?: string;
  duration?: number;
  timeRemaining?: number;
  expired?: boolean;
  catalogId: string;
  courseCatalogId: string,
  courseId: number;
  lecturePageId: number;
  lectureComponentId: number;

  communications?: Communication[],
  communicationsCount?: number,
  hardDeadlinePassed?: boolean,

  // These 4 props may only exist on the lecture page component
  // TOOD: Move to a new type?
  hardDeadline: boolean,
  graded: boolean,
  maximumAttempts: number,
  isTodo: false,

  hasStructuralIssues?: boolean;
  questionsAttributes?: QuizAIQuestion[];
} & MinimalQuiz;

export type NTimelineQuiz = Normalized<TimelineQuiz, 'communications'>;

export interface TimelineQuizzesNormalized { [id: string]: NTimelineQuiz }

export type AllQuestionViewQuiz = {
  totalQuestions: number;
  unsubmittedSubmission: UnsubmittedSubmission,
  submission: UnsubmittedSubmission,
  questions: QuizQuestion[],
} & TimelineQuiz;

export type NAllQuestionViewQuiz = Normalized<AllQuestionViewQuiz, 'communications' | 'questions'>;

export interface AllQuestionViewQuizNormalized { [id: string]: NAllQuestionViewQuiz }

export interface SubmissionResponse {
  id: number
  questionId: number
  questionSetSubmissionId: null
  response: number | number[] | string
  otherResponse: null
  submissionRevisionId: number
  childrenQuestionResponses: null
  isCorrect?: boolean
  numberOfAttempts?: number
  numberOfCorrectOptions?: number
  attemptFeedback: null
  feedback: AllQuestionViewResponseOption[]
}

export interface SubmissionQuiz {
  id: number
  title: 'Quiz'
  graded: boolean
  answerableQuestionsCount: number
  activityType: 'QuestionSet'
  progress: 'in_progress'
  correctAnswersCount: number
}

export interface UnsubmittedSubmission {
  id: number
  exerciseId: number
  type: 'questionsetsubmission'
  lastSavedAt: string
  owner: null
  questionSet: null
  exercise: null
  score: number
  maxScore: number
  numberOfAttempts: number
  editable: boolean
  pointsReceived: null
  leaderboardPoints: number
  pointsReceivedFromPreviousAttempt: number
  leaderboardRank: null
  priorLeaderboardRank: null
  startedExamAt: null
  timeRemaining: number
  releaseResultsAt: null
  questionsSubmitted: boolean
  windowId: number
  questions: AllQuestionQuizQuestion[]
  responses: SubmissionResponse[]
  quiz: SubmissionQuiz
}

export enum AiQuestionType {
  MULTIPLE_CHOICE_SINGLE_ANSWER = 'multiple_choice_single_answer',
  MULTIPLE_CHOICE_MULTIPLE_ANSWER = 'multiple_choice_multiple_answer',
  TRUE_OR_FALSE = 'true_or_false',
  FILL_BLANKS = 'fill_blanks',
}

export enum AllQuestionViewQuizQuestionType {
  MULTIPLE_CHOICE = 'MultipleChoiceQuestion',
  MULTIPLE_CHOICE_MULTIPLE_ANSWER = 'MultipleChoiceMultipleAnswerQuestion',
  SHORT_ANSWER = 'ShortTextQuestion',
  NUMBER_ANSWER = 'NumberQuestion',
  STATEMENT = 'ReadOnlyQuestion',
}

export type QuizAIQuestion = {
  id: string;
  questionText: string;
  type: string;
  responseOptionsAttributes: QuizAIQuestionOption[];
};

type QuizAIQuestionOption = {
  optionContent: string,
  explanation: string,
  isCorrect: boolean,
};

export type AllQuestionViewResponseOption = {
  id: number;
  index: number;
  questionId: number;
  isCorrect: boolean;
  optionContent: string;
  optionFeedback: null | string;
  explanation: string;
  secondaryExplanation: string;
  bottomOfRange: string,
  topOfRange: string,
  readOnly?: boolean,
  votesCount?: null,
  voters?: null,
};

export type AllQuestionQuizQuestion = {
  id: number;
  isRequired: boolean;
  questionText: string;
  questionIndex: number;
  questionSetId: number;
  type: QuizQuestionType;
  questionFileUpload?: QuestionFileUpload;
  responses: null | QuizQuestionResponse;
  // Admin will only get this property
  correctAnswersCount?: number;
  totalQuestionAttempts: number;
  completedQuestionAttempts: number;
  // Admin will only get this property
  attemptFeedbacks?: QuizQuestionFeedback[];
  // Learner will only get this property
  attemptFeedback?: QuizQuestionFeedback;
  responseOptions: ResponseOption[];
  retry: number;
  answerableQuestionPosition: number | null;
  answerableQuestionPositions: number[];
  second: null,
  childrenQuestions: null,
  numRows: null,
  numColumns: null,
  horizontalAxisLabel: null,
  verticalAxisLabel: null,
  leftLimit: null,
  rightLimit: null,
};
