import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import moment from 'moment';
import t from 'react-translate';
import { pick } from 'lodash';
import { css } from '@emotion/core';
import * as Yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// redux
import { ComponentType, LectureComponent } from 'redux/schemas/models/lecture-component';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { getCurrentCourse } from 'redux/selectors/course';
import { RootState } from 'redux/schemas';
import { updateLectureComponent } from 'redux/actions/lecture-pages';
import { wrapThunkAction } from 'redux/utils';
import { NQuizQuestion, QuizQuestionType } from 'redux/schemas/models/progressive-quiz';
import { getAllQuestionViewQuizQuestions } from 'redux/selectors/quizzes';
import { SubmissionResponse } from 'redux/schemas/models/quiz';
import { resetNewComponent } from 'redux/actions/lecture-components';
import { getQuiz } from 'redux/selectors/timeline';

import BaseLectureComponentContext from 'lecture_pages/directives/components/base-lecture-component/context';
import { DeepPartial } from 'utility-types';
import { NvDropdownOption } from 'shared/components/inputs/nv-dropdown';
import { isEmpty } from 'underscore';
import NvModal, { ModalType } from 'shared/components/nv-modal';
import { AllQuestionViewQuizAttemptSettingModal } from 'quizzes/components/quiz-basic-setting-modal';
import QuizModal from 'quizzes/components/quiz-modal';
import { gray6 } from 'styles/global_defaults/colors';
import { AllQuestionViewQuizFormStateType } from '../../../quizzes/components/context';
import { LectureComponentProps } from '..';
import QuizComponentTitleContent from '../quiz/shared/quiz-lecture-component-title-content';
import SubmitSection from './submit-section';
import AllQuestionViewQuestion from './all-question-view-question';

type QuizLectureComponentType = LectureComponent<ComponentType.QUIZ>;
type ForwardOnQuestionsModalCloseRefType = {
  callback: Function,
  restrictModalClose: boolean,
};
export enum SavingStatus {
  SAVING = 'saving',
  SAVED = 'saved',
  SAVE = 'save',
}
const QuizLectureComponent = (props: LectureComponentProps<ComponentType.QUIZ>) => {
  const {
    mode,
    currentLecture,
    lectureComponent,
  } = props;

  const styles = css`
    .questions-list {
      border-top: 1px solid ${gray6};
    }
  `;

  const quiz = useSelector((state) => getQuiz(state, lectureComponent.quiz));

  const questions: NQuizQuestion[] = useSelector(
    (state: RootState) => getAllQuestionViewQuizQuestions(state, quiz.id),
  );
  const { expirationDate } = quiz;

  const dispatch = useAppDispatch();
  const currentLectureId = currentLecture.id;
  const currentCourse = useSelector(getCurrentCourse);
  const isCurrentCourseSelfPaced = currentCourse.isSelfPaced;

  const forwardOnQuestionsModalCloseRef = useRef<ForwardOnQuestionsModalCloseRefType>(
    {
      callback: () => {},
      restrictModalClose: false,
    },
  );

  const [showEditBasics, setShowEditBasics] = useState(false);
  const [showCorrectAnswers, setShowCorrectAnswers] = useState(false);

  const currentCatalogId = useSelector((state) => state.app.currentCatalogId);
  const isNewComponent = useSelector((state) => state.app.newComponent === lectureComponent?.id) ?? false;
  const [showEditQuestions, setShowEditQuestions] = useState(false);
  const [saveStatus, setSaveStatus] = useState(SavingStatus.SAVE);
  const lectureComponentId = lectureComponent.id;
  const isUnlimitedAttempts = quiz.maximumAttempts === 0 || !quiz.maximumAttempts;

  let defaultValues = {};
  questions.forEach((eachQuestion) => {
    if (eachQuestion.answerableQuestionPosition !== null) {
      defaultValues = { ...defaultValues, [eachQuestion.id]: '' };
    }
  });


  let validationShape = {};
  questions.forEach((eachQuestion) => {
    if (eachQuestion.type === QuizQuestionType.ALL_QUESTION_VIEW_SHORT_TEXT_QUESTION) {
      validationShape = { ...validationShape,
        [eachQuestion.id]: Yup.string()
          .required(t.VALIDATION.REQUIRED())
          .max(255, t.VALIDATION.MAX('255')),
      };
    }
    if (eachQuestion.type === QuizQuestionType.NUMBER_QUESTION) {
      validationShape = { ...validationShape,
        [eachQuestion.id]: Yup.number()
          .transform(value => (Number.isNaN(value) ? undefined : value))
          .required(t.VALIDATION.REQUIRED())
          .typeError(t.VALIDATION.NUMBER()),
      };
    }
  });

  const ValidationSchema = Yup.object().shape(validationShape);

  const form = useForm<AllQuestionViewQuizFormStateType>({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(ValidationSchema),
  });

  const {
    setValue,
  } = form;

  useEffect(() => {
    if (isNewComponent) {
      setShowEditQuestions(true);
      dispatch(resetNewComponent());
    }
  }, [dispatch, isNewComponent]);

  useEffect(() => {
    if (!isEmpty(quiz.unsubmittedSubmission)) {
      const UnSubmittedSubmissionResponses: SubmissionResponse[] = quiz.unsubmittedSubmission.responses;
      UnSubmittedSubmissionResponses.forEach((eachResponse: SubmissionResponse, index) => {
        setValue(eachResponse.questionId.toString(), eachResponse.response);
      });
      if (saveStatus === SavingStatus.SAVING) {
        setTimeout(() => {
          setSaveStatus(SavingStatus.SAVED);
        });
      }
    }
    /**
     * Removing saveStatus from dependency array because function need to trigger
     * only when unsubmittedSubmission changes
     */
  }, [quiz.unsubmittedSubmission, quiz.submission, setValue]);

  const { sharedProps, setSharedProps } = useContext(BaseLectureComponentContext);
  const initialSharedProps = useRef(sharedProps).current;

  const lecturePageId = useSelector((state: RootState) => state.app.lecturePage.currentLectureId);
  const course = useSelector((state) => getCurrentCourse(state));
  const lecturePage = useSelector(state => state.models.lecturePages[lecturePageId]);

  const openEditQuestions = useCallback(() => {
    setShowEditQuestions(true);
  }, []);

  const editBasics = () => setShowEditBasics(true);

  const saveComponent = React.useCallback((componentDataPatch: DeepPartial<QuizLectureComponentType['quiz']>) => {
    const componentDataUpdate = {
      catalogId: currentCatalogId,
      lecturePageId: currentLectureId,
      componentData: {
        id: lectureComponentId,
        type: lectureComponent.type,
        quiz: {
          ...pick(quiz, [
            'isTodo',
            'hardDeadline',
            'expirationDate',
            'maximumAttempts',
            'questionMaximumAttempts',
          ]),
          ...componentDataPatch,
        },
      },
    };

    return dispatch(updateLectureComponent(componentDataUpdate as any));
  }, [
    dispatch,
    quiz,
    currentLectureId,
    currentCatalogId,
    lectureComponentId,
    lectureComponent.type,
  ]);

  React.useEffect(() => {
    const options: NvDropdownOption[] = [{
      type: 'text',
      callback: editBasics,
      text: t.LECTURE_PAGES.COMPONENTS.DROPDOWN.EDIT_BASICS(),
      disabled: !!lecturePage.isLinked,
      tooltip: {
        enabled: !!lecturePage.isLinked,
        text: t.LECTURE_PAGES.COMPONENTS.SET_UP_FROM_COLLECTION_TOOLTIP(),
        placement: 'left',
        offset: 20,
      },
    }, {
      type: 'text',
      callback: openEditQuestions,
      text: t.LECTURE_PAGES.COMPONENTS.QUIZ.DROPDOWN.EDIT_QUESTIONS(),
      disabled: !!lecturePage.isLinked,
      tooltip: {
        enabled: !!lecturePage.isLinked,
        text: t.LECTURE_PAGES.COMPONENTS.SET_UP_FROM_COLLECTION_TOOLTIP(),
        placement: 'left',
        offset: 20,
      },
    }];

    if (!isCurrentCourseSelfPaced && !isUnlimitedAttempts) {
      options.push(
        { type: 'text',
          text: expirationDate ? t.LECTURE_PAGES.COMPONENTS.DROPDOWN.REMOVE_DEADLINE() : t.LECTURE_PAGES.COMPONENTS.DROPDOWN.ADD_DEADLINE(),
          disabled: course.isContentManagementCollection,
          tooltip: {
            enabled: course.isContentManagementCollection,
            text: t.LECTURE_PAGES.COMPONENTS.DROPDOWN.SETUP_IN_LINKED_LESSON_TOOLTIP(),
            placement: 'left',
            offset: 20,
          },
          callback: () => {
            saveComponent({
              expirationDate: expirationDate
                ? null
                : moment(currentLecture.releaseDate).add(1, 'weeks').endOf('day').toISOString(),
            });
          },
        },
      );
    }

    setSharedProps({
      ...initialSharedProps,
      extraOptions: {
        mode: 'prepend',
        renderOnMount: true,
        options,
      },
    });
  }, [
    saveComponent,
    setSharedProps,
    expirationDate,
    openEditQuestions,
    initialSharedProps,
    isCurrentCourseSelfPaced,
    currentLecture.releaseDate,
  ]);

  const closeQuestionModal = () => {
    forwardOnQuestionsModalCloseRef?.current?.callback();
    if (!forwardOnQuestionsModalCloseRef?.current?.restrictModalClose) {
      setShowEditQuestions(false);
    }
  };

  return (
    <div css={styles} className='d-flex flex-column align-items-center pb-6'>
      <QuizComponentTitleContent
        type={ComponentType.QUIZ}
        quiz={quiz}
        mode={mode}
        currentLecture={currentLecture}
        saveComponent={saveComponent}
      />
      <FormProvider {...form}>
        <form className='w-100'>
          <div className='w-100 questions-list mt-2'>
            {questions?.map((question) => (
              <AllQuestionViewQuestion
                currentQuestion={question}
                quiz={quiz}
                mode={mode}
                lectureComponent={lectureComponent}
                showCorrectAnswers={showCorrectAnswers}
              />
            ))}
          </div>
          {!!quiz?.questions?.length && (
          <SubmitSection
            mode={mode}
            questionSetId={quiz.id}
            lectureComponent={lectureComponent}
            showCorrectAnswers={showCorrectAnswers}
            setShowCorrectAnswers={setShowCorrectAnswers}
            saveStatus={saveStatus}
            setSaveStatus={setSaveStatus}
          />
          )}
        </form>
      </FormProvider>
      <NvModal
        header={t.LECTURE_PAGES.COMPONENTS.QUIZ.MODAL.TITLE.EDIT()}
        show={showEditBasics}
        fullHeight={false}
        type={ModalType.DYNAMIC}
        onClose={() => setShowEditBasics(false)}
        body={(
          <AllQuestionViewQuizAttemptSettingModal
            isEdit
            currentAttempts={quiz.maximumAttempts}
            onCancel={() => setShowEditBasics(false)}
            onConfirm={async (payload) => {
              wrapThunkAction(dispatch(updateLectureComponent({
                catalogId: currentCatalogId,
                lecturePageId,
                componentData: {
                  id: lectureComponent.id,
                  type: lectureComponent.type,
                  ...payload,
                },
              }))).then(() => setShowEditBasics(false));
            }}
          />
        )}
      />
      <NvModal
        show={showEditQuestions}
        fullHeight={false}
        type={ModalType.NO_DIALOG}
        onClose={closeQuestionModal}
        body={(
          <div className='modal-page'>
            <QuizModal
              mode={0}
              closeModal={closeQuestionModal}
              reveal={false}
              lectureComponentId={lectureComponentId}
              questionIndex={0}
              isAllQuestionViewQuiz
              forwardOnQuestionsModalCloseRef={forwardOnQuestionsModalCloseRef}
            />
          </div>
        )}
      />
    </div>
  );
};

export default QuizLectureComponent;
