import React, { useState } from 'react';
import { clone, pluck } from 'underscore';
import { useSelector } from 'react-redux';

// redux
import { getCurrentCourse } from 'redux/selectors/course';
import { RootState } from 'redux/schemas';
import { getLecturePage } from 'redux/selectors/timeline';
import { getAllQuestionViewQuizQuestions,
  getQuizQuestionOptions,
  getSubmissionQuestionResponse,
} from 'redux/selectors/quizzes';
import { NAllQuestionViewQuiz } from 'redux/schemas/models/quiz';
import { ComponentType, NLectureComponent } from 'redux/schemas/models/lecture-component';

import useSavingRegistry, { SavingRegistryContext } from 'shared/hooks/use-saving-registry';
import QuizQuestion from 'quizzes/components/quiz-question';
import QuizContent from 'quizzes/components/quiz-content';
import { NQuizQuestion, QuizQuestionType, ResponseOption } from 'redux/schemas/models/progressive-quiz';
import CorrectAnswer from 'quizzes/components/correct-answer';
import { css } from '@emotion/core';
import { gray5, gray6 } from 'styles/global_defaults/colors';
import { doubleSpacing } from 'styles/global_defaults/scaffolding';
import QuizContext,
{ SavingIndicator, QuestionContext } from '../../../quizzes/components/context';
import { LecturePageMode } from '..';

type AllQuestionViewQuestionProps = {
  currentQuestion: NQuizQuestion
  quiz: NAllQuestionViewQuiz;
  lectureComponent: NLectureComponent<ComponentType.QUIZ>;
  mode: LecturePageMode;
  showCorrectAnswers: boolean;
  isEditQuestionMode?: boolean;
};

const AllQuestionViewQuestion = (props: AllQuestionViewQuestionProps) => {
  const {
    quiz,
    lectureComponent,
    mode,
    showCorrectAnswers,
    isEditQuestionMode,
  } = props;

  const styles = css`
    .question-container {
      border-bottom: 1px solid ${gray6};
      .number-container {
        width: ${doubleSpacing}px;
      }
      .question-content {
        width: calc(100% - ${doubleSpacing}px);
        .correct-answer-container {
          border-top: 4px solid ${gray5};
        }
      }
    }
  `;

  const questions: NQuizQuestion[] = useSelector(
    (state: RootState) => getAllQuestionViewQuizQuestions(state, quiz.id),
  );
  const ownerLecturePage = useSelector((state: RootState) => getLecturePage(state, lectureComponent.id));

  const [savingStatus, setSavingStatus] = useState(SavingIndicator.HIDDEN_STATUS);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const { currentQuestion } = props;

  const currentCourse = useSelector(getCurrentCourse);
  const canMakeStructuralChanges = currentCourse.isContentManagementCollection ? !quiz.submission : !ownerLecturePage?.released;
  const savingIndicatorTimeoutRef = React.useRef<null | NodeJS.Timeout>(null);
  const savingRegistry = useSavingRegistry();
  const responses = useSelector((state: RootState) => getSubmissionQuestionResponse(state, currentQuestion.id, currentQuestion.questionSetId));
  const isHardDeadlineExpired = quiz.hardDeadline && quiz.expired;
  const isStatement = currentQuestion.type === QuizQuestionType.STATEMENT;

  const getCurrentQuestionResponse = () => {
    if (quiz.progress === 'in_progress') {
      return null;
    }
    if (quiz.unsubmittedSubmission) {
      return null;
    }
    if (responses?.length) {
      const response = clone(responses[0]);
      response.feedback = pluck(response.feedback, 'id');
      return response;
    }
    return null;
  };

  const quizContextValue = {
    questions,
    setSavingStatus,
    currentQuestion,
    quiz,
    currentQuestionIndex,
    setCurrentQuestionIndex,
    lectureComponent,
    canMakeStructuralChanges,
    savingIndicatorTimeoutRef,
    isAllQuestionViewQuiz: true,
    currentQuestionResponse: getCurrentQuestionResponse(),
    lecturePageMode: mode,
    isHardDeadlineExpired,
  };
  const responseOptions: ResponseOption[] = useSelector(
    (state: RootState) => getQuizQuestionOptions(state, currentQuestion?.id),
  );

  const questionContextValue = {
    currentQuestion,
    responseOptions,
    currentQuestionResponse: getCurrentQuestionResponse(),
  };

  return (
    <SavingRegistryContext.Provider value={savingRegistry}>
      <QuizContext.Provider value={quizContextValue}>
        <QuestionContext.Provider value={questionContextValue}>
          {isEditQuestionMode ? (
            <QuizContent />
          ) : (
            <div css={styles}>
              <div className='d-flex w-100 question-container pt-6 pb-2'>
                <div className='number-container text-large-regular bold'>
                  {currentQuestion.answerableQuestionPosition}
                </div>
                <div className='d-flex flex-column flex-grow-1 question-content'>
                  <QuizQuestion currentQuestion={currentQuestion} />
                  { !isStatement && (mode === LecturePageMode.VIEW) && showCorrectAnswers && (
                    <div className='correct-answer-container mb-5 mt-2'>
                      <CorrectAnswer className='mt-1' />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </QuestionContext.Provider>
      </QuizContext.Provider>
    </SavingRegistryContext.Provider>
  );
};

export default AllQuestionViewQuestion;
